import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import useBreakpoints from "../hooks/useBreakpoints";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorUtil } from "../libs/formikError";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  DriveFile,
  DriveFolder,
  GeneratedDocument,
  ServiceSchedule,
} from "../typings/models";
import { generateServiceScheduleTemplate } from "../api/api";
import useSignal from "../hooks/useSignal";
import { VehicleTypeEnum } from "../typings/enums";

const requiredMessage = "Acest câmp este obligatoriu";

const validationSchema = Yup.object({
  service1: Yup.string().required(requiredMessage),
  service2: Yup.string().required(requiredMessage),
  service3: Yup.string().required(requiredMessage),
  service4: Yup.string().required(requiredMessage),
  service5: Yup.string().required(requiredMessage),
  service6: Yup.string().required(requiredMessage),
  service7: Yup.string().required(requiredMessage),
  service8: Yup.string().required(requiredMessage),
  service9: Yup.string().required(requiredMessage),
  service10: Yup.string().required(requiredMessage),
});

const FORM_DEFAULT_VALUES: ServiceSchedule = {
  service1: "",
  service2: "",
  service3: "",
  service4: "",
  service5: "",
  service6: "",
  service7: "",
  service8: "",
  service9: "",
  service10: "",
};

const SEVICE_INTERVAL_UNITS = ["km", "ore", "rezervoare"];

interface ServiceScheduleDialogProps {
  open?: boolean;
  sourceFile: DriveFile;
  model: DriveFolder;
  onClose?: (res?: GeneratedDocument) => void;
  sx?: Style;
}

const ServiceScheduleDialog: React.FC<ServiceScheduleDialogProps> = (props) => {
  const { open, sourceFile, model, onClose = () => {}, sx } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<string>(SEVICE_INTERVAL_UNITS[0]);
  const { isMobile } = useBreakpoints();
  const signal = useSignal();

  const onSubmit = async (data: ServiceSchedule) => {
    const dataWithUnits: ServiceSchedule = Object.entries(data).reduce(
      (acc, [key, value]) => {
        acc[key as keyof ServiceSchedule] = `${value} ${units}`;
        return acc;
      },
      {} as ServiceSchedule,
    );
    try {
      setLoading(true);
      const res = await generateServiceScheduleTemplate(
        dataWithUnits,
        sourceFile.id,
        model.id,
        { signal },
      );
      if (signal.aborted) return;
      setLoading(false);
      console.log(res);
      if (res.status !== "success") {
        alert(res.message ?? "Something went wrong");
        return;
      }
      onClose(res.data);
    } catch (e: any) {
      alert("Error");
    }
  };

  const {
    values,
    handleSubmit,
    errors,
    touched,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormik({
    initialValues: FORM_DEFAULT_VALUES,
    validationSchema,
    validateOnMount: true,
    onSubmit: onSubmit,
  });

  const errorHandling = errorUtil(touched, errors);

  const handleChange = async (name: string, value: any, touch?: boolean) => {
    await setFieldValue(name, value);
    if (touch) {
      await setFieldTouched(name, true);
    }
  };

  const handleBlur = async (name: string, touch?: boolean) => {
    await setFieldTouched(name, touch);
  };

  if (!open) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        maxWidth="md"
        fullScreen={isMobile}
        sx={styles.dialog}
      >
        <DialogTitle sx={styles.dialogTitle}>
          Create service schedule template for {model.name}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={styles.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Stack sx={styles.form} component="form" onSubmit={handleSubmit}>
            <Autocomplete
              freeSolo
              options={SEVICE_INTERVAL_UNITS}
              value={units}
              onChange={(ev, value) => setUnits(value ?? "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unități de măsură"
                  onChange={(ev) => setUnits(ev.target.value)}
                  required
                />
              )}
            />

            <TextField
              label="Service #1"
              value={values.service1}
              onChange={(ev) => handleChange("service1", ev.target.value)}
              onBlur={() => handleBlur("service1", true)}
              error={errorHandling.isInvalid("service1")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #2"
              value={values.service2}
              onChange={(ev) => handleChange("service2", ev.target.value)}
              onBlur={() => handleBlur("service2", true)}
              error={errorHandling.isInvalid("service2")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #3"
              value={values.service3}
              onChange={(ev) => handleChange("service3", ev.target.value)}
              onBlur={() => handleBlur("service3", true)}
              error={errorHandling.isInvalid("service3")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #4"
              value={values.service4}
              onChange={(ev) => handleChange("service4", ev.target.value)}
              onBlur={() => handleBlur("service4", true)}
              error={errorHandling.isInvalid("service4")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #5"
              value={values.service5}
              onChange={(ev) => handleChange("service5", ev.target.value)}
              onBlur={() => handleBlur("service5", true)}
              error={errorHandling.isInvalid("service5")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #6"
              value={values.service6}
              onChange={(ev) => handleChange("service6", ev.target.value)}
              onBlur={() => handleBlur("service6", true)}
              error={errorHandling.isInvalid("service6")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #7"
              value={values.service7}
              onChange={(ev) => handleChange("service7", ev.target.value)}
              onBlur={() => handleBlur("service7", true)}
              error={errorHandling.isInvalid("service7")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #8"
              value={values.service8}
              onChange={(ev) => handleChange("service8", ev.target.value)}
              onBlur={() => handleBlur("service8", true)}
              error={errorHandling.isInvalid("service8")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #9"
              value={values.service9}
              onChange={(ev) => handleChange("service9", ev.target.value)}
              onBlur={() => handleBlur("service9", true)}
              error={errorHandling.isInvalid("service9")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
            <TextField
              label="Service #10"
              value={values.service10}
              onChange={(ev) => handleChange("service10", ev.target.value)}
              onBlur={() => handleBlur("service10", true)}
              error={errorHandling.isInvalid("service10")}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{units}</InputAdornment>
                ),
              }}
              sx={styles.input}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogFooter}>
          <Button
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => onSubmit(values)}
            type="button"
            variant="contained"
            disabled={!isValid}
            loading={loading}
            sx={styles.submit}
          >
            Create template
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceScheduleDialog;

const styles = mapStyles({
  root: {},
  dialog: {},
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "12px",
    py: 1,
  },
  dialogContent: {
    display: "flex",
    paddingBottom: 1,
    px: "12px",
  },
  dialogFooter: {
    px: "12px",
    paddingBottom: "12px",
    justifyContent: "space-between",
  },
  close: {
    marginRight: -1,
  },
  form: {
    width: "100%",
    gap: 1,
    mt: 1,
  },
  input: {
    width: "100%",
  },
  submit: {
    minWidth: "min(25%, 100px)",
  },
});
